<template>
  <VSkeletonLoader v-if="!loaded" type="table-thead, table-tbody, table-tfoot"/>
  <v-data-table v-else
                v-bind="$attrs"
                v-on="$listeners"
                :class="{'row-pointer': hasRowClickListener, 'expandable-table': showExpand}"
                :headers="datatableHeaders"
                :items="paginator.data"
                :loading="isLoading"
                :server-items-length="totalItems"
                :showExpand="showExpand"
                @update:options="handleUpdatePagination"
                :options.sync="footerOptions"
                :footer-props="{itemsPerPageOptions: [5,10,15,25],
                   itemsPerPageText: $t('global.$vuetify.dataFooter.itemsPerPageText'),
                   pageText: $t('global.$vuetify.dataFooter.pageText')}">
    <template v-for="(_, name) in itemSlots" v-slot:[name]="props">
      <slot :header="props.header" :item="props.item" :name="name" :value="props.header"/>
    </template>
    <template #item.actions="{item}">
      <div class="crud-actions text-right">
        <slot name="actions" :item="item"></slot>
        <v-btn v-if="hasClickUpdateListener('edit') && permissionChecker('update')"
               color="text--secondary" icon
               @click.stop="$emit('click:edit', item)">
          <v-icon>$edit</v-icon>
        </v-btn>
        <v-btn v-if="hasClickUpdateListener('delete') && permissionChecker('destroy')"
               color="text--secondary" icon
               @click.stop="$emit('click:delete', item)">
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>
    </template>
    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <slot :item="item" name="expanded-item"></slot>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import Paginator from '@/application/mixins/Paginator.vue';
import { translateField } from '@/application/util/translation.js';
import { mapGetters } from 'vuex';

export default {
  name: 'KCrudTable',
  mixins: [Paginator],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    resource: { // this is used to check for permissions
      type: String,
    },
    languagePrefix: {
      required: false,
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationIsCalled: false,
    };
  },
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
    }),
    totalItems() {
      return this.paginator?.meta?.total;
    },
    initialOptions() {
      return this.$attrs.options;
    },
    itemSlots() {
      return Object.fromEntries(Object.entries(this.$scopedSlots).filter(([name]) => name.startsWith('item.')));
    },
    datatableHeaders() {
      return [
        ...this.headers.map((header) => {
          return {
            ...header,
            text: header.text ?? (header.language ? translateField(header.language, this.languagePrefix) : translateField(header.value, this.languagePrefix)),
            class: 'text--secondary',
          };
        }),
        {
          value: 'actions',
          align: 'end',
          cellClass: 'no-table-separator',
          sortable: false,
        },
      ];
    },
    hasRowClickListener() {
      return !!this.$listeners['click:row'];
    },
  },
  async created() {
    const options = await this.initialOptions || {};
    this.updatePagination(options);
  },
  methods: {
    /**
     * Because vuetify is updating the pagination onLoad we need to stop the first time from getting passed.
     * Using this "updatePagination" as the first call is not possible since this is only called after the initial data
     * load by vuetify.
     *
     * https://github.com/vuetifyjs/vuetify/issues/12865
     */
    handleUpdatePagination() {
      if (this.paginationIsCalled) {
        this.updatePagination(...arguments);
      }
      this.paginationIsCalled = true;
    },
    reload() {
      this.reloadPaginator();
    },
    permissionChecker(permission) {
      return true;
    },
    hasClickUpdateListener(type) {
      return this.$listeners && this.$listeners[`click:${type}`];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table.row-pointer tr {
    cursor: pointer;
  }

  tbody > tr > td:not(.v-data-table__mobile-row),
  thead > tr:last-child > th, {
    border-bottom: 0 !important;
  }

  tbody td + td:not(.no-table-separator) {
    border-image:       linear-gradient(to bottom, rgba(0, 0, 0, 0) 11px, rgba(0, 0, 0, 0.25) 11px, rgba(0, 0, 0, 0.25) calc(100% - 11px), rgba(0, 0, 0, 0) calc(100% - 11px));
    border-image-slice: 1;
    border-left-style:  solid;
    border-left-width:  1px;
  }

  tbody td.table-cell-align--top {
    vertical-align: text-top;
  }

  .expandable-table tbody tr td:nth-child(2) {
    border: none;
  }

  .v-data-footer {
    border-top: 0;
  }

  tbody tr:nth-child(odd) {
    background-color: $oddTableRow;
  }
}
</style>
